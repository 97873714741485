import { APIFilter } from '@/utils/api'

export default {
  async selectSobreDestinatario (Vue, filter, search, sorter, page, idsobre) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idsobre', idsobre)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.sobreDestinatario.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'sobreDestinatario.select', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSobreDestinatarioRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idsobre_destinatario', pks)
    const resp = await Vue.$api.call('sobreDestinatario.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteSobreDestinatario (Vue, idsobreDestinatario) {
    await Vue.$api.call('sobreDestinatario.delete', { idsobre_destinatario: idsobreDestinatario })
  },
}
